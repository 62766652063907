import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeSlugFromLocation } from '../../../Location/locationProviderPropsMap';
import { ChallengeServerlessData } from '../../../../api/challenges/challenge.api';
import requestChallenge from '../api/requestChallenge';
import { shouldReportErrorMonitor } from './shouldReportErrorMonitor';
import { handleError } from '../../../ErrorHandler/errorHandlerPropsMap';

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const challengeId = getChallengeSlugFromLocation(flowAPI);

    let challengeData: ChallengeServerlessData = {
      challenge: null,
    };

    try {
      if (challengeId) {
        challengeData = await requestChallenge(challengeId, flowAPI);
      }
    } catch (error) {
      handleError({
        error,
        context: 'onUserLogin.getChallenge',
        preventErrorMonitorReport: !shouldReportErrorMonitor(error),
      });
    }

    flowAPI.controllerConfig.setProps({
      challengeData,
    });
  });
}
