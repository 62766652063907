import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';
import type { Section as SectionV3 } from '@wix/ambassador-online-programs-v3-section/types';

import { Step, Section } from '../types/v3Types';

export const isV3Step = (step: Step): boolean => {
  return (step as StepV3).revision !== undefined;
};

export const isV3Section = (section: Section): boolean => {
  return (section as SectionV3).revision !== undefined;
};
