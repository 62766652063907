import { SectionV3WithSteps, StepWithDelay } from '../types/v3Types';
import type { Section as SectionV3 } from '@wix/ambassador-online-programs-v3-section/types';
import type { Step as StepV3 } from '@wix/ambassador-online-programs-v3-step/types';

export interface ISectionV3WithSteps {
  sections: SectionV3WithSteps[];
  steps: StepWithDelay[];
}
export const getSectionsWithSteps = (
  sections: SectionV3[],
  steps: StepV3[],
): ISectionV3WithSteps => {
  const sectionsById = sections.reduce((acc, section) => {
    acc[section.id] = { ...section, steps: [] };
    return acc;
  }, {} as Record<string, SectionV3WithSteps>);
  const stepWithDelay: StepWithDelay[] = [];
  const sectionsWithSteps = steps.reduce((acc, step) => {
    const section = sectionsById[step.sectionId];
    if (!section) {
      return acc;
    }
    const _step = { ...step, delay: section.delayInDays || 0 };
    stepWithDelay.push(_step);
    acc[section.id].steps.push(_step);
    return acc;
  }, sectionsById);
  return {
    sections: Object.values(sectionsWithSteps) as SectionV3WithSteps[],
    steps: stepWithDelay,
  };
};
