import { ISettingsContextValue } from '@wix/tpa-settings';
import challengeSettings, {
  ALL_PROGRAM_CATEGORY,
} from '../components/ChallengesList/settingsParams';
import { ChallengeSettingsParams } from '../components/ChallengesList/Settings/challengeSettings/challengeSettings.types';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Category } from '@wix/ambassador-achievements-categories-v3-category/types';
import { IChallengeInState } from '../components/ChallengesList/Settings/tabs/Challenges/Challenges';

export const getCategoriesSetup = (
  settings: ISettingsContextValue,
  categories?: Category[],
) => {
  const isCategoriesEnabled =
    // categories?.length &&
    settings.get(
      challengeSettings[ChallengeSettingsParams.DisplayCategoriesTabs],
    );
  const chosenCategoryFromSettings = isCategoriesEnabled
    ? settings.get(
        challengeSettings[ChallengeSettingsParams.CategoriesTabsChosenCategory],
      )
    : null;
  const isAllProgramCategory =
    chosenCategoryFromSettings &&
    chosenCategoryFromSettings === ALL_PROGRAM_CATEGORY;
  const isExistingInDBCategory =
    chosenCategoryFromSettings &&
    (isAllProgramCategory ||
      (categories || []).find(
        (category) => category.id === chosenCategoryFromSettings,
      ));

  const isShowCategoriesMenu = isAllProgramCategory || !isExistingInDBCategory;

  return {
    isCategoriesEnabled,
    chosenCategoryFromSettings,
    isAllProgramCategory,
    isExistingInDBCategory,
    isShowCategoriesMenu,
  };
};

export const filterChallengesByCategory = (
  challenges: MemberChallenge[] = [],
  categoryId: string,
) => {
  return categoryId === ALL_PROGRAM_CATEGORY
    ? challenges
    : challenges.filter(
        (challenge) =>
          (challenge.challenge as any).categoryIds?.includes(categoryId) ||
          (challenge.challenge as any).settings.categoryIds?.includes(
            categoryId,
          ),
      );
};

export function filterChallengesByCategoryForSettings(
  challenges: IChallengeInState[],
  categoryId: string,
  isCategoriesEnabled: boolean,
) {
  if (categoryId === ALL_PROGRAM_CATEGORY || !isCategoriesEnabled) {
    return challenges;
  }

  return challenges.filter((ch) => ch?.categories.includes(categoryId));
}
